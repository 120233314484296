import Dashboard from './dashboard';

export default function Home(): JSX.Element {
  return (
    <div>
      <main>
        <Dashboard />
      </main>
    </div>
  );
}
